<template>
    <div id="sms_port_add_id">
        <!-- 短信主端口号新增 -->
        <el-dialog class="cur_dialog" title="" center :visible.sync="dialog_visible" fullscreen destroy-on-close :show-close="true" :modal-append-to-body="false">
            <!-- 头 -->
            <el-row class="dialog_top">
                <span>新增短信主端口号</span>
                <i class="el-icon-close dialog_top_close_button" @click="back()" style="font-size:24px"></i>
            </el-row>

            <!-- 体 -->
            <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">

                <el-row style="overflow: auto;padding-right:10px;" :style="{height: (content_heigth+'px')}">
                    <el-form :model="form_data" label-width="120px" style="margin-bottom: 12px;">

                        <el-form-item label="短信主端口号">
                            <el-input type="number" v-model="form_data.sms_port" placeholder="请输入短信主端口号" size="mini">
                            </el-input>
                        </el-form-item>

                        <el-form-item label="省份">
                            <el-select v-model="form_data.province_code" placeholder="请选择省份" size="mini" style="width:100%"> 
                                <el-option
                                    v-for="item in provinceList"
                                    :key="item.id"
                                    :label="item.province_name"
                                    :value="item.id"
                                    >
                                </el-option>
                            </el-select>
                        </el-form-item>

                        <el-form-item label="登录账号">
                            <el-input v-model="form_data.username" placeholder="请输入登录账号" size="mini" type="text"></el-input>
                        </el-form-item>

                        <el-form-item label="登录密码">
                            <el-input v-model="form_data.password" placeholder="请输入登录密码" size="mini"></el-input>
                        </el-form-item>

                        <el-form-item>
                            <el-button type="primary" @click="onSubmit()" size="mini">保存</el-button>
                        </el-form-item>
                    </el-form>

                </el-row>

            </el-row>


        </el-dialog>
    </div>
</template>
<script>
import API from '../../../api/api';

export default {
    props:{
        prop_change_i: 0,
    },
    watch:{//监听props
        prop_change_i(val){
            //数据清除
            this.dataClear();
            if(val == 0){
                this.dialog_visible = false;//弹出框是否可见 
            }else{
                this.dialog_visible = true;//弹出框是否可见 
                setTimeout(() => {
                    //请求接口-获取短信主端口省份列表
                    this.getSmsPortProvinceList();
                }, 1);
            }
        }
    },

    data(){
        return{
            dialog_visible: false,//弹出框是否可见  false:不可见

            content_width:300,//内容的=宽度
            content_heigth:500,//内容的高度

            provinceList:[],//省份列表

            form_data:{
                sms_port: "",//短信主端口号
                province_code:'',//省份
                username:'',//登录账号
                password:'',//登录密码
            },
           
        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
        //初始化设置
        this.initSetUp();

    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
        },
         //数据清除
        dataClear(){
            this.form_data = this.$options.data().form_data;//单个恢复初始化
        },
        //请求接口-获取短信主端口省份列表
        getSmsPortProvinceList(){
            API.OperationManagerServlet({
                param: "sms_port_province",
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.provinceList = res.list;
                }
            });
        },
        //提交
        onSubmit(){
            if(API.isEmtry(this.form_data.sms_port)){
                this.$message.error("请填写短信主端口号");
                return;
            }
            if(API.isEmtry(this.form_data.province_code)){
                this.$message.error("请选择省份");
                return;
            }
            if(API.isEmtry(this.form_data.username)){
                this.$message.error("请填写登录账号");
                return;
            }
            if(API.isEmtry(this.form_data.password)){
                this.$message.error("请填写登录密码");
                return;
            }
            
            API.OperationManagerServlet({
                param: "add_sms_port",
                sms_port:this.form_data.sms_port,
                province_code:this.form_data.province_code,
                username:this.form_data.username,
                password:this.form_data.password,
            }).then((res) => {
                //console.log(res);
                if (res.recode === 0) {
                    this.$message({
                        message: "恭喜您，提交成功",
                        type: "success",
                        offset: 280
                    });
                    this.dialog_visible = false;//弹出框是否可见   false:不可见
                    this.$emit("add_sms_port", "");
                }
            });
        },
        //关闭
        back(){
            this.dialog_visible = false;//弹出框是否可见   false:不可见
        },

    }
};

</script>

<style scoped>
/* 增加或编辑弹框中内容距离标题的距离 */
.cur_dialog >>>.el-dialog__body {
    padding: 0px 0px;
}
.cur_dialog >>>.el-dialog__header {
    padding: 0px 0px;
}
/*form表单 间隔*/
.el-form-item {
  margin-bottom: 10px;
}
</style>