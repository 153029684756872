<template>
    <!-- 主端口列表 -->
    <div id="sms_port_list_id">
        <!-- 头 -->
        <el-row class="head_top">
            <div class="head_top_back cursor"><i class="el-icon-arrow-left" @click="goBack()"></i></div>
            <div class="head_top_right">
                <el-button type="primary" @click="add()" size="mini">
                    新增
                </el-button>
            </div>
            <div class="head_top_title">短信主端口号</div>
        </el-row>

        <!-- 体 -->
        <el-row style="margin:10px 0px 0px;padding:0px 15px;background-color: white;">
            <el-row style="overflow: auto;" :style="{height: ((content_heigth)+'px')}">
                <el-table  :data="tableData" :show-header="true" border style="width: 100%" size="mini">
                    <el-table-column prop="sms_port" label="短信主端口号" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="province_code" label="省份" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="username" label="登录账号" min-width="80" align="center"> </el-table-column>
                    <el-table-column prop="password" label="登录密码" min-width="80" align="center"> </el-table-column>
                    <el-table-column label="管理" min-width="80" align="center">
                        <template slot-scope="scope">
                            <el-button type="text" size="mini" @click="sub_sms_port_list(scope.row.sms_port)">查看</el-button>
                            <el-button type="text" size="mini" @click="del_sms_port(scope.row.id)">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </el-row>
        </el-row>

        <!-- 新增短信主端口号 -->
        <SmsPortAdd :prop_change_i="prop_sms_port_i" @add_sms_port="sms_port_list"></SmsPortAdd>

    </div>
</template>
<script>
import API from '../../../api/api';
import SmsPortAdd from './sms_port_add.vue';//组件 新增主端口号

export default {

    components: {
        SmsPortAdd
    },
    
    data(){
        return{
            content_heigth:500,//内容的高度
            content_width:500,//内容的宽度

            tableData:[],//表格数据

            //新建短信主端口号
            prop_sms_port_i: 0,//新建短信主端口号  组件 弹出框是否可见

        }
    },
    //第二个生命周期函数，在created 中，data 和 methods 都已经被初始化好了！
    created() {
    },
    //activated和deactivated配合keep-alive标签使用!
    activated() {
        //重置所有数据
	    Object.assign(this.$data, this.$options.data.call(this));
	    //初始化设置
        this.initSetUp();
        
        //查询
        this.getData();
    },
    //方法
    methods: {
        //初始化设置
        initSetUp(){
            let clientWidth = document.body.clientWidth;
            let clientHeight = document.body.clientHeight;
            // console.log("document.body.clientWidth:"+document.body.clientWidth+" "+document.body.clientHeight);
            this.content_width = clientWidth;
            this.content_heigth = (clientHeight - 80);
            //console.log("content_heigth:"+this.content_heigth+" clientHeight:"+clientHeight);
        },
        //查询
        getData(){
            //请求接口
            API.OperationManagerServlet({
                param: "sms_port_list",
            }).then((res) => {
                if (res.recode === 0) {
                    this.tableData = res.list;

                    this.$nextTick(function(){
                        //获取分页的高度
                        var pagination = document.getElementById('pagination_id');
                        if(!API.isEmtry(pagination)){
                            this.pagination_height = pagination.clientHeight;
                        }
                    });
                } 
            });
        },
        //返回
        goBack() {
            API.router_to("/operation_index");
        },
        //添加主端口
        add(){
            this.prop_sms_port_i ++;//弹出框是否可见
        },
        //添加主端口后回调
        sms_port_list(){
            this.getData();
        },
        //查看子端口列表
        sub_sms_port_list(sms_port) {
            API.router_to("/sub_sms_port_list/"+sms_port);
        },
        //删除主端口号
        del_sms_port(id) {
            this.$confirm('此操作将永久删除短信主端口号, 是否继续?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                    //请求接口
                    API.OperationManagerServlet({
                        param: "del_sms_port",
                        id:id,
                    }).then((res) => {
                        if (res.recode === 0) {
                            this.$message({showClose: true,message: '恭喜你，删除成功', type: 'success'});
                            //重新加载数据
                            this.getData();
                        }
                    });
            }).catch(() => {//已取消删除
            });
        },
    }
};
</script>

<style scoped>
/*form表单样式调整*/
#app_check_rate_list>>>.el-form-item {
  margin-bottom: 0px;
}
</style>